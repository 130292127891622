import React from "react"
import { Button, Tabs, Tab, Form, Container, Row, Col } from "react-bootstrap";
import { Link } from "@StarberryUtils"
import ScrollAnimation from 'react-animate-on-scroll'
import ReactMarkdown from "react-markdown"
import './Content.scss';
import SocialShare from "@Components/Share/SocialShare";
import { SaveItem } from "@Components/myaccount/wishlistlink"
import { JOBS_PAGE_URL, JOBSA_PAGE_URL, JOBSD_PAGE_URL } from "@Components/common/site/constants";
const AllContent = (props) => {
    return (
        <React.Fragment>

            <section className="all-content">
                <Container>
                    <Row>
                        <Col md={12}>
                            <ScrollAnimation animateOnce={true} animateIn='fadeIn'>

                                <div className="about-content">
                                    {/* {JSON.stringify(props.content.replace(/<[^>]+>/g, ''))} */}
                                    <div className="job-description job-description-list" dangerouslySetInnerHTML={{__html: props.content}} />
                                {/* <ReactMarkdown source={props.content.replace(/<span id=\"spandesc\">]+>/g, '')} allowDangerousHtml /> */}

                                    <div className="btn-groups d-none d-md-flex">
                                        <Link to={JOBSA_PAGE_URL.alias+'/'+props.id} className="btn btn-primary">
                                            apply for this job                                                                                
                                            </Link>
                                            {/* <SaveItem itemId={props.ObjectId} itemClass="like" /> */}
                                        {/* <Link href="#" className="like">
                                            <i className="icon-like">

                                            </i>
                                            <span className="sm-text">
                                                shortlist
                                        </span>
                                        </Link> */}
                                        <SocialShare job_url={process.env.GATSBY_SITE_URL+JOBSD_PAGE_URL.alias+'/'+props.id} jobDetails={true} Title="Share" />
                                        {/* <Link href="#" className="like">
                                            <i className="icon-share">
                                            </i>
                                            <span className="sm-text">
                                                share
                                        </span>
                                        </Link> */}
                                    </div>

                                </div>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>

    )
};
export default AllContent;