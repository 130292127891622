import React, { useState, useEffect } from "react";
import Header from "@Components/Header/Header"
import JobDetailsPage from "@Components/JobDetails/JobDetails"
import SlidePage from "@Components/JobDetails/Slide/Slide"
import OfficerPage from "@Components/JobDetails/Officer/Officer"
import AboutContent from "@Components/JobDetails/AllContent/Content"
import ReCaptchaBox from '@Components/forms/elements/recaptcha'
import SearchPage from "@Components/JobDetails/SearchProfile/SearchProfile"
import { useQuery } from '@apollo/client';
import axios from "axios"
import * as qs from "query-string"
import { postFormData } from "./../Components/forms/api/Api"
import SEO from "@Components/seo";
import gql from "graphql-tag"
import { Container, Modal, Row, Col, Form, Button, Tabs, Tab, FormLabel } from 'react-bootstrap';
import FooterPage from "@Components/Footer/Footer"
import CareersForm from "@Components/forms/career";
import { JOBS_PAGE_URL, JOBSA_PAGE_URL, JOBSD_PAGE_URL, JOBSD } from "@Components/common/site/constants";
import { Link } from "@StarberryUtils"
import { useStaticQuery, graphql } from "gatsby";
import VideoStep from "@Components/AdviceCentre/CareersForm/VideoStep";
import { useHistory } from 'react-router-dom';
import $ from "jquery";
import {
  isIOS,
  isMobileOnly,
  isBrowser,
  isMobile,
  isChrome,
  isFirefox,
  isSafari
} from "react-device-detect";
    // Capitalize
    const capitalize = (s) => {
      if (typeof s !== 'string') return ''
      return s.charAt(0).toUpperCase() + s.slice(1)
  }
  // Capitalize

const JOB_DETAIL = gql`
  query GetJobs ($url: String!){
    jobs(where:{id: $url}) {
      jobopeningid
        posting_title
        job_type
      salary
      salaryNAN
      currency
      city
      state
      job_description
      industry
    }
  }
`;


const JobDetails = (props) => {
  useEffect(() => {
    $("input[name=telephone]").keypress(function (e) {
      //if the letter is not digit then display error and don't type anything
      if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
         //display error message
         $("#errmsg").html("Digits Only").show().fadeOut("slow");
                return false;
     }
    });
  });
  const [videoblob, setvideoblob] = useState(null)
  const [videotrue, setvideotrue] = useState(false)
  const history = useHistory();
  const path = typeof window !== 'undefined' ? window.location.pathname?.indexOf('apply') > -1 : false
  const path1 = typeof window !== 'undefined' ? window.location.pathname?.split("/").pop() === 'all' : false
  // console.log(videoblob)
  const data = useStaticQuery(graphql`
query{
  glstrapi {
    jobs(where: {publish: true}, sort:"published_at:desc") {
      _id
      slug
    }
  }
}
`);
  useEffect(() => {

    // setStep(sessionStorage.getItem('step') != null ? parseInt(sessionStorage.getItem('step')) : 0)
    setName(sessionStorage.getItem('name') != null ? sessionStorage.getItem('name') : '')
    setEmail(sessionStorage.getItem('email') != null ? sessionStorage.getItem('email') : '')
    setPhone(sessionStorage.getItem('telephone') != null ? sessionStorage.getItem('telephone') : '')
    setTab(sessionStorage.getItem('tab') != null ? sessionStorage.getItem('tab') : 'email')
    setFile(sessionStorage.getItem('file') != null ? JSON.parse(sessionStorage.getItem('file')) : '')
    // setlinkedin(sessionStorage.getItem('linkedin') != null ? JSON.parse(sessionStorage.getItem('linkedin')) : '')
  }, []);
  const recaptchaRef = React.createRef();
  const [formvalues, setFormvalues] = useState("");
  const [token, setToken] = useState("");
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [MassApplylist, setMassApply] = useState()
  const [step, setStep] = useState(0)
  const [name, setName] = useState('')
  const [AlerMsg, setAlerMsg] = useState('')
  const [nameError, setNameError] = useState(false)
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState(false)
  const [valemailError, setValEmailError] = useState(false)
  const [phone, setPhone] = useState('')
  const [phoneError, setPhoneError] = useState(false)
  const [valphoneError, setValPhoneError] = useState(false)
  const skiplinkin = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
    setStep(step + 1)
  }
  const [linkedin, setlinkedin] = useState('')
  const [file, setFile] = useState([])
  const [fileError, setFileError] = useState(false)
  const [tab, setTab] = useState('email')
  const onChange = (e) => {
    sessionStorage.setItem(e.target.name, e.target.value)
    if (e.target.name === 'name') {
      setName(e.target.value)
      if (e.target.value !== "") {
        setNameError(false)
      }
      else {
        setNameError(true)
      }
    }
    if (e.target.name === 'email') {
      setEmail(e.target.value)
      if (e.target.value === "") {
        setEmailError(true)
      }
      else if (e.target.value.match("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$") === null) {
        setValEmailError(true)
        setEmailError(false)
      }
      else {
        setEmailError(false)
        setValEmailError(false)
      }
    }
    if (e.target.name === 'telephone') {
      setPhone(e.target.value)
      if (e.target.value === "") {
        setPhoneError(true)
      }
      else if (e.target.value.match("^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$") === null) {
        setValPhoneError(true)
        setPhoneError(false)
      }
      else {
        setPhoneError(false)
        setValPhoneError(false)
      }
    }
    if (e.target.name === 'linkedin') {
      setlinkedin(e.target.value)
    }

  }
  const [fileskip, setfileskip] = useState(false)
const gofileSkip = event => {
  setStep(step + 1)
  setFileError(false)
  setfileskip(true)
  
}

  const next = event => {
    if (step === 0) {
      // if (!file?.name) {
      //   setFileError(true)
      // }
      // else {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
        setStep(step + 1)
        // setfileskip(false)
      // }
    }
    else if (step === 1) {
      if (!name) {
        setNameError(true)
      }
      else {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
        setStep(step + 1)
      }
    }
    else if (step === 2) {
        if (!email || email.match("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$") === null) {
          if (!email) {
            setEmailError(true)
          }
          if (email.match("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$") === null) {
            setValEmailError(true)
          }
        }
        else {
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          })
          setStep(step + 1)
        }
    }
    else if (step === 3) {
      
      if (!phone || phone.match("^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$") === null) {
        if (!phone) {
          setPhoneError(true)
        }
        if (phone.match("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$") === null) {
          setValPhoneError(true)
        }
      }
      else {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
        setStep(step + 1)
      }

    }
    else if (step === 4) {
      // if (!file?.name) {
      //   setFileError(true)
      // }
      // else {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
        setStep(step + 1)
        setfileskip(false)
      // }
    }
    else if (step === 5) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
      setStep(step + 1)
    }
    else if (step === 6) {
      const formsdata = (event.target);
      event.preventDefault();
      const json = {}
      Object.keys(formsdata).map(key => (
          json[formsdata[key].name] = (formsdata[key].checked) ? 'yes' : formsdata[key].value
      ))
      json['email_temp_user'] = "apply_job_user";
      json['email_temp_admin'] = "apply_job_admin";
      json['formname'] = "Apply YOUR CV";
      if (json['subject']) {
          json['formname'] = 'Apply YOUR CV';
      }
      json['email'] = sessionStorage.getItem('email');
      json['name'] = sessionStorage.getItem('name');
      json['telephone'] = sessionStorage.getItem('telephone');
      json['linkedin'] = sessionStorage.getItem('linkedin');
      json['files'] = videoblob ? videoblob : '';
      // json['files'] = file;
      json['job_url'] = path1 ? sessionStorage.getItem('checkedItemstitles')  : process.env.GATSBY_SITE_URL+JOBSD_PAGE_URL.alias+'/'+props.id;
      json['g-recaptcha-response'] = token;
      // json['to_email_id'] = process.env.BOOKAVIEW_MAIL_TO
      setFormvalues(json);

      recaptchaRef.current.execute();
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
      // setStep(step + 1)
    }
    else if (step === 7) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
      setStep(step + 1)
    }

  }
  const previous = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
    setStep(step - 1)
    setAlerMsg("")
    // setCamera(false)

  }
  useEffect(() => {
    // Initialize Bootstarp file upload field value retain js
    // bsCustomFileInput.init()

    if (token !== '') {
     



        const processFromData = async () => {

          let formData = new FormData();
          // debugger
          if (formvalues?.files) {
            //formData.append('files.attachment', formvalues.files, formvalues.files.name);
            // appending file
            formData.append('files.videos', formvalues.files, formvalues.files.name);
          }
          if(file && !fileskip){
            formData.append('files.attachment', file, file.name);
          }
          // if (formvalues?.files) {
          //   formData.append('files.attachment', formvalues.files, formvalues.files.name); // appending file
          // }

            formData.append('data', JSON.stringify(formvalues));

            postFormData(formData).then(apiRes => {

              if (apiRes?.attachment?.url) {
                // If image from externall url, use that
                if (apiRes.attachment.url.match(/(http|https):\/\//g)) {
                  formvalues['file'] = `${apiRes.attachment?.url}`;
                  formvalues['cvname'] = `${apiRes.attachment?.url}`;
                  formvalues['videoname'] = `${apiRes.videos?.url}`;
                } else { // or we consider it is loading from api url
                  formvalues['file'] = `${process.env.GATSBY_STRAPI_FORM_URL}${apiRes.attachment?.url}`;
                  formvalues['cvname'] = `${process.env.GATSBY_STRAPI_FORM_URL}${apiRes.attachment?.url}`;
                  formvalues['videoname'] = `${process.env.GATSBY_STRAPI_FORM_URL}${apiRes.videos?.url}`;
                }
              }

              const applyJobData = {
                "jobid": path1 ? sessionStorage.getItem('checkedItemsValues') : job_details?.jobs[0]?.jobopeningid,
                "jobtitle": path1 ? sessionStorage.getItem('checkedItemstitles') : job_details?.jobs[0]?.posting_title,
                "name": name,
                "email": email,
                "telephone": phone,
                "resume": apiRes.attachment?.url ? formvalues['file'] : '',
                "video": apiRes.videos?.url ? formvalues['videoname'] : ''
                }
            axios.post(process.env.GATSBY_STRAPI_SRC+'/zoho-api/sendtoZoho', applyJobData)
                .then(response =>{

                  // console.log(response)
                  if(response?.data?.message?.data?.length === 1 && response?.data?.message?.data[0]?.code === "SUCCESS"){
                    setStep(step + 1)
                    const axiosOptions_email = {
                      url: '/.netlify/functions/server/apply_job',
                      method: "post",
                      headers: { "Content-Type": "application/x-www-form-urlencoded" },
                      data: qs.stringify(formvalues),
                  }
  
                  axios(axiosOptions_email)
                      .then(response => {
                          // console.log('mail sent!')
                          // next()
                          sessionStorage.removeItem('email');
                          sessionStorage.removeItem('name');
                          sessionStorage.removeItem('telephone');
                          sessionStorage.removeItem('file');
                          sessionStorage.removeItem('linkedin');
                          
  
                      })
                      .catch(err =>
                        {
  
                        }
                      );

                  }
                  else if(response?.data?.message?.data?.length > 1){
                          var jobList = sessionStorage.getItem('checkedItemstitlesid')
                          var joblist1 = jobList.split(',')
                          var titleList = joblist1.map((t, i) => {
                          var item1 = t.split('+++')
                          if (item1) { 
                              var xitem = {
                                "jobname" : item1[1],
                                "Jobid" : item1[0]
                              }
                              return xitem;
                          }
                          });
                          let result = response?.data?.message?.data.map((x, index) => {
                            var item = titleList.find(item => item.Jobid === x.details.jobid)
                            if(x.code === "SUCCESS"){
                              var xitem = {
                                "jobname" : item.jobname,
                                "code" : "SUCCESS"
                              }
                            }   
                            else {
                              var xitem = {
                                "jobname" : item.jobname,
                                "code" : "FAILURE"
                              }
                            }   
                            return xitem
                          });

                    setMassApply(result)
                    setStep(step + 1)
                    const axiosOptions_email = {
                      url: '/.netlify/functions/server/apply_job',
                      method: "post",
                      headers: { "Content-Type": "application/x-www-form-urlencoded" },
                      data: qs.stringify(formvalues),
                  }
  
                  axios(axiosOptions_email)
                      .then(response => {
                          // console.log('mail sent!')
                          // next()
                          sessionStorage.removeItem('email');
                          sessionStorage.removeItem('name');
                          sessionStorage.removeItem('telephone');
                          sessionStorage.removeItem('file');
                          sessionStorage.removeItem('linkedin');
                          
  
                      })
                      .catch(err =>
                        {
  
                        }
                      );
                  }
                  else {
                    setAlerMsg("We have your records for this job position in our database already, our team will review your profile and get back to you with an update.")
                  }

                }
                  ).catch(error => {
                  console.error('There was an error!', error);
              });

                
            });

              // tracking event
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                'event': 'formSubmit',
                'formType': 'apply-job',
                'formId': 'apply-job',
                'formName': 'Apply Job',
                'formLabel': 'Apply Job'
              });

              // next()

        }
        processFromData();
    }
}, [token]);
  const handleonVerify = token => {
    // console.log("captcha verified");
    setToken(token);
  };
  const tabClick = (text) => {
    sessionStorage.setItem('tab', text)
    if (text === 'email') {
      setPhoneError(false)
      setValPhoneError(false)
      setPhone('')
      setTab(text)
    }
    if (text === 'phone') {
      setEmailError(false)
      setValEmailError(false)
      setEmail('')
      setTab(text)
    }

  }
  const goSearch = () => {
    history.push(JOBS_PAGE_URL.alias)
  }
  let property_slug = props.id?.split('-');
  let id = property_slug ? property_slug[property_slug.length-1] : null;
  const { loading, error, data: job_details } = useQuery(JOB_DETAIL, {
    variables: { url: id }
  });

  const handlechange = event => {
    // remove initial empty spaces
    event.target.value = event.target.value.trimStart()
      
    }
    const [showcamera, setCamera] = useState(false)
    const [camerayes, setcamerayes] = useState(false)
    const [isVideo, setisVideo] = useState(false)
    
  const goCamera = () => {
    setCamera(true)
    setcamerayes(true)
  }

  const handleAttachment = event => {
    sessionStorage.setItem('file', JSON.stringify(event.target.files[0]))

    const fileItem = event.target.files[0]; // accesing file

      if (event.target.files[0] !== "") {
        setFileError(false)
        setFile(fileItem)
      }
      else {
        setFileError(true)
      }
  //   const fileItem = event.target.files[0]; // accesing file
  //   var d = document.getElementById('custom-file');
  //   //alert(d.value);
  //  $('.form-control-browsefile .custom-file-label').html(d.value.replace(/C:\\fakepath\\/, ''));
  //  setFile(fileItem); // storing file

};

  if (loading) return (
    <div className="static-news gql-loading-screen">
      <Container>
        <div className="gql-loading"><div className="dot-pulse"></div></div>
      </Container>
    </div>);
  return (
    <div className="wrapper slide job-deatils-page-footer-fix">
            <SEO 
            title={capitalize(job_details?.jobs[0]?.job_type.replace(/-/g, ' ')) +' '+ capitalize(job_details?.jobs[0]?.posting_title.replace(/-/g, ' ')) + " Job in " + capitalize(job_details?.jobs[0]?.city.replace(/-/g, ' '))+ capitalize(job_details?.jobs[0]?.city ? ', ' : '') + capitalize(job_details?.jobs[0]?.state) +' '+ capitalize(job_details?.jobs[0]?.salaryNAN ? '' : job_details?.jobs[0]?.salary ? 'with '+job_details?.jobs[0]?.salary?.replace(/-/g, ' ') +' AED' : null)} 
            description={"Find the details of "+capitalize(job_details?.jobs[0]?.job_type.replace(/-/g, ' '))+' '+ capitalize(job_details?.jobs[0]?.posting_title.replace(/-/g, ' ')) + " job in "+capitalize(job_details?.jobs[0]?.city.replace(/-/g, ' '))+ capitalize(job_details?.jobs[0]?.city ? ', ' : '') + capitalize(job_details?.jobs[0]?.state)+ capitalize(job_details?.jobs[0]?.salaryNAN ? '' : job_details?.jobs[0]?.salary ? " with "+job_details?.jobs[0]?.salary?.replace(/-/g, ' ') +' AED' : null)+" and apply for the job with Allsopp & Allsopp Recruitment."} />            
            <h1 style={{display:"none"}}>
              {capitalize(job_details?.jobs[0]?.job_type.replace(/-/g, ' ')) +' '+ capitalize(job_details?.jobs[0]?.posting_title.replace(/-/g, ' ')) + " Job in " + capitalize(job_details?.jobs[0]?.city.replace(/-/g, ' '))+ capitalize(job_details?.jobs[0]?.city ? ', ' : '') + capitalize(job_details?.jobs[0]?.state) +' '+ capitalize(job_details?.jobs[0]?.salaryNAN ? "" : job_details?.jobs[0]?.salary ? 'with '+job_details?.jobs[0]?.salary?.replace(/-/g, ' ') +' AED' : null)}
              </h1>
      <div className="jobdetails-header"><Header nobanner={true} /></div>
     <SlidePage path1={path1} videotrue={videotrue} previous={previous} step={step} path={path} links={data.glstrapi.jobs} id={id} backid={props.id} />
      {!path ? <>
      {/* {JSON.stringify(job_details)} */}
        <OfficerPage ObjectId={id ? id : ''} handleShow={handleShow} id={props.id} data={job_details?.jobs[0]} />
        <AboutContent ObjectId={id ? id : ''} handleShow={handleShow} id={props.id} content={job_details?.jobs[0]?.job_description} />
        <SearchPage ObjectId={id ? id : ''} handleShow={handleShow} id={props.id} title={job_details?.jobs[0]?.industry} />
      </> :
        <section className="stepper-form">
          <Container>
            <Row>
              <Col md={12}>
                <Form className="tab-form career-fr">
                  {/* {path1 ? 'true' : 'false'} */}
                  {
                                          step === 0 ?
                                          <div>
                                            <h3>You can add your Linkedin Profile URL if you like</h3>
                                            <Form.Group>
                                                  <Form.Control autoComplete="off" value={linkedin} onChange={onChange} name="linkedin" type="text" placeholder="Linkedin Profile" />
                                              <Button onClick={next} className="cv go-btn">Go</Button>
                                              <button onClick={skiplinkin} className="cv no-border btn btn-primary">Skip</button>
                                              
                                            </Form.Group>
                                          </div>
:                  
                  step === 1 ?
                    <div>
                      <h3>Are you interested in making an application as {job_details?.jobs[0]?.posting_title}?</h3>
                      {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam venenatis auctor nibh at sollicitudin. Aenean mollis purus sit amet libero placerat condim.</p> */}
                      <Form.Group>
                        <Form.Control className={nameError ? 'error-border' : ''} autoComplete="off" value={name} onChange={onChange} name="name" type="text" placeholder="What’s your name?" />
                        {nameError &&
                          <div className="error">
                            Please Enter name.
                    </div>
                        }
                        <Button onClick={next} className="go-btn">Go</Button>

                      </Form.Group>


                    </div>
                    : step === 2 ?
                      <div className="contact-us">
                        <h3>Hello there {name}, <br />let's start with your contact details.</h3>
                        <div className="contact">
                        
                              <Form.Group>
                                <Form.Control className={emailError ? 'error-border' : valemailError ? 'error-border' : ''} autoComplete="off" value={email} onChange={onChange} name="email" type="email" placeholder="Email Address" />
                                {emailError &&
                                  <div className="error">
                                    Please Enter Email.
                    </div>
                                }
                                {valemailError && !emailError &&
                                  <div className="error">
                                    Please Enter Valid Email.
                    </div>
                                }
                                <Button onClick={next} className="go-btn">Go</Button>
                              </Form.Group>

                        </div>
                      </div>
                      : step === 3 ?
                      <div className="contact-us">
                        <h3>Hello there {name}, <br />let's start with your contact details.</h3>
                        <div className="contact">
                      
                              <Form.Group>
                                <Form.Control maxLength={13} className={phoneError ? 'error-border' : valphoneError ? 'error-border' : ''} autoComplete="off" value={phone} onChange={onChange} name="telephone" type="text" placeholder="Telephone Number" />
                                {phoneError &&
                                  <div className="error">
                                    Please Enter Telephone.
                    </div>
                                }
                                {valphoneError && !phoneError &&
                                  <div className="error">
                                    Please Enter Valid Telephone.
                    </div>
                                }
                                <Button onClick={next} className="go-btn">Go</Button>
                              </Form.Group>
                            
                        </div>
                      </div>
                      : step === 4 ?
                        <div>
                          <h3>Thanks {name}, we'll be in touch<br/>Psst, you can upload your CV if you like</h3>
                          <p>We prefer in the .doc, .docx, rtf, or pdf formats.</p>
                          <Form.Group className="filebk">
                            <FormLabel className={fileError ? 'error-border file-label' : "file-label"}>{file?.name ? file?.name : "Click here to attach you CV"}</FormLabel>
                          <Form.Control
        required={true}
        type="file"
        name="file"
        // value=""
        onChange={(event) => handleAttachment(event)}
        accept={".pdf, .doc, .docx, .rtf"}
        
        id="custom-file"
      />
       {fileError &&
                          <div className="error">
                            Please upload file.
                    </div>
                        }
                            {/* <Form.Control id="exampleFormControlFile1" placeholder="Click here to attach you CV" /> */}
                            <Button onClick={next} className="cv go-btn">Go</Button>
                            <Button onClick={gofileSkip} className="cv no-border">Skip</Button>
                            
                          </Form.Group>
                        </div>
                        : step === 5 ?
                          <div>
                            <h3>What makes you the ideal candidate for this position? In 30 seconds, tell us.</h3>
                            <p>This will work best in Chrome browser</p>
                            {isSafari ? 
                                <>
                                <p className="red-color"><b>Video Support in safari is Experimental</b></p>
                    <p className="red-color">Experiencing problems in this browser? Try using Chrome or FireFox for better experience</p>

                                </>
                                :
                                <>
                            {showcamera ? <VideoStep next={props.next} stepcamera={true} setisVideo={setisVideo} setvideotrue={setvideotrue} setvideoblob={setvideoblob}/> : null}
                            </>
                            }
                              
                            <div className="bt-bk">
{/* {camerayes ? 'true' : 'false'}
{isVideo  ? 'true' : 'false'} */}
                              {camerayes && isVideo  ? <Button disabled={videotrue} onClick={next} >Go</Button> : showcamera ? null :  <>
                              {isSafari ? null
                              :<Button onClick={goCamera}>grant access to webcam</Button>}
                              <Button onClick={next} className={isSafari ? "btn btn-primary" : "no-border"}>Skip</Button>
                              
                              </>}
                            </div>

                          </div>
                          : step === 6 ?
                            <div>
                              {AlerMsg ?<div className="alertMsg">{AlerMsg}</div> : null}
                              <h3>Your Application</h3>
                              <Form.Group>
                                <Form.Label>Full Name</Form.Label>
                                <Form.Control autoComplete="off" value={name} type="text" disabled={true} />
                              </Form.Group>
                                <Form.Group>
                                  <Form.Label>Email Address</Form.Label>
                                  <Form.Control autoComplete="off" value={email} type="text" disabled={true} />
                                </Form.Group>

                                <Form.Group>
                                  <Form.Label>Telephone</Form.Label>
                                  <Form.Control autoComplete="off" value={phone} type="text" disabled={true} />
                                </Form.Group>
                              {file?.name && !fileskip &&
                              <Form.Group>
                                <Form.Label>Resume</Form.Label>
                                <Form.Control autoComplete="off" value={file?.name} type="text" disabled={true} />
                              </Form.Group>
                              }
                              {linkedin &&
                              <Form.Group>
                                <Form.Label>Linkedin Profile</Form.Label>
                                <Form.Control autoComplete="off" value={linkedin} type="text" disabled={true} />
                              </Form.Group>
                              }
                              {camerayes && isVideo &&
                              <Form.Group>
                                <Form.Label>What makes me the  ideal candidate</Form.Label>
                                <Form.Control autoComplete="off" value={videoblob?.name+"."+videoblob?.type?.replace("video/", "")} type="text" disabled={true} />
                              </Form.Group>
                              }
                              <div className="bt-bk">
                                <Button onClick={next}>Submit Application</Button>
                                <Link to={path1? '/for-candidates/job-search' : JOBSD_PAGE_URL.alias + '/' + props.id} className="btn btn-primary no-border">Close</Link>
                              </div>
                            </div>
                            : step === 7 ?
                              <div>
                                <div className="tick"></div>
                                <h3 className="center">Thank you for your application, <br />we'll review it and if you've been successful one of our team will be in touch. <br />Best of luck!<br /></h3>
{MassApplylist && MassApplylist.length > 0 && 

<div className="mass-apply-bk-msg"> 
{MassApplylist.map((hit, i) => {
return (
  hit.code === "SUCCESS" ?
<div className={hit.code+ " mesalert"}> 
  "{hit.jobname}" job is associated successfully
</div>
: hit.code === "FAILURE" ?
<div className={hit.code+ " mesalert"}> 
  "{hit.jobname}" job is already associated.
</div>
: null
)})
}
</div>
}

                                <div className="bt-bk center">
                                  <Link className="btn btn-primary" to={path1 ? '/job-wishlist' : JOBS_PAGE_URL.alias}>Back to search</Link>
                                  <Link to={path1? '/for-candidates/job-search' : JOBSD_PAGE_URL.alias + '/' + props.id} className="btn btn-primary no-border">Close</Link>
                                </div>
                              </div>
                              : null
                  }
                  <ReCaptchaBox
                className="py-2"
                captRef={recaptchaRef}
                key="captcha"
                handleonVerify={handleonVerify}
            />
                </Form>
                {/* <CareersForm job_url={process.env.GATSBY_SITE_URL+"jobs/detail/"+props.id} /> */}
              </Col>
            </Row>
          </Container>
        </section>

      }
      {/* {url} */}
      <FooterPage />
      <Modal show={show}
        size="lg"
        backdrop="static"
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title> Apply this {job_details?.jobs[0]?.posting_title} job</Modal.Title>
        </Modal.Header>
        <Modal.Body><CareersForm job_url={process.env.GATSBY_SITE_URL + JOBSD_PAGE_URL.alias + props.id} /></Modal.Body>

      </Modal>
    </div>
  )

}

export default JobDetails